import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // url: "http://localhost:3850/api/",
    url: "https://app-techsoft.herokuapp.com/api/",
    // url: "http://192.168.1.4:3850/api/",
    userListRefresh: 0,
  },
  mutations: {
    updateUserList(store, payload) {
      store.userListRefresh++
    },
    setProductListKey(store, payload) { 
      store.productListKey++
    },
  },
  actions: {
  },
  modules: {
  }
})
