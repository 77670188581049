<template>
  <div id="new-home">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center vh-100 img-bakground">
        <div class="col-md-12 text-center mt-5">
          <h1 class="text-uppercase text-light mt-5 animate__animated animate__pulse" ><b>Crecemos tus ventas</b></h1>
          <p class="h4 text-light mb-5 "><b> Te garantizamos el incremento de tus ventas online un 50% el primer mes</b></p>
          <b-button pill variant="primary" size="lg" class="mt-5 shadow-lg" >
            <span class="h6 mr-2 font-weight-bold " >ME INTERESA</span>
            <b-icon icon="arrow-right" aria-hidden="true"></b-icon>
          </b-button>
            
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row mt-4 mb-5 ">
        <div class="col-md-12">
          <div class="row justify-content-center align-item-center responsive-row-1">

            <div class="p-md-2 mr-2 ml-2 bg-primary text-white col-md-3 mt-2 animate__animated animate__bounce">
              <div class="row">
                <div class="col-md-12 text-center mt-3 mb-3">
                  <img class="img-fluid mr-2 responsive" width="30" src="../assets/Inicio/iman.png" alt="">
                  <span class="h6" style="font-size:1.2rem !important">ATRAPAMOS CLIENTES</span>
                </div>
              </div>
            </div>
            <div class="p-md-2 mr-2 ml-2 bg-primary text-white col-md-3 mt-2 animate__animated animate__bounce">
              <div class="row">
                <div class="col-md-12 text-center mt-3 mb-3">
                  <img class="img-fluid mr-2 responsive" width="40" src="../assets/Inicio/idea.png" alt="">
                  <span class="h6" style="font-size:1.2rem !important">CREAMOS SOLUCIONES</span>
                </div>
              </div>
            </div>
            <div class="p-md-2 mr-2 ml-2 bg-primary text-white col-md-3 mt-2 animate__animated animate__bounce ">
              <div class="row">
                <div class="col-md-12 text-center mt-3 mb-3">
                  <img class="img-fluid mr-2 responsive" width="30" src="../assets/Inicio/manos.png" alt="">
                  <span class="h6" style="font-size:1.2rem !important">CONCRETAMOS NEGOCIOS</span>
                </div>
              </div>
            </div>

            <!-- <div class="p-md-2  mr-2 ml-2 bg-primary text-white col-md-3 mt-2">
              <div class="row">
                <div class="col-2 mt-2 mt-md-1">
                  <img class=" img-fluid " width="44" src="../assets/Inicio/idea.png" alt="">
                </div>
                <div class="col-7 ml-md-3 mt-3 mb-3 mb-md-1">
                  <span class="h5 font-weight-bold">CREAMOS SOddLUCIONES</span>
                </div>
              </div>
            </div>
            <div class="p-md-2  mr-2 ml-2 bg-primary text-white col-md-3 mt-2">
              <div class="row">
                <div class="col-2 mt-2 mt-md-2">
                  <img class=" img-fluid " width="44" src="../assets/Inicio/manos.png" alt="">
                </div>
                <div class="col-7 ml-md-3 mt-3 mb-3 mb-md-1">
                  <span class="h5 font-weight-bold">ATRAPAMOS CLIENTES</span>
                </div>
              </div>
            </div> -->

          </div>
          <!--  -->
          
          <!--  -->
        </div>
      </div>
      <div  class="row mt-5 text-center">
        <div class=" p-3 col-md-5 offset-md-1 mt-5">
          <h2 class=" text-uppercase text-primary mt-4" data-aos="zoom-in"><b>¿por qué techsoft?</b></h2>
          <p class="text-primary h4 mt-2" data-aos="zoom-in" >nuestro principal objetivo es crecer las ventas online de las empresas en el menor tiempo disponible</p>
        </div>
        <div class="col-md-4">
          <img class="img-fluid" data-aos="zoom-in" data-aos-delay="100" src="../assets/Inicio/grafico-01 1.png" alt="">
        </div>
      </div>
    </div><hr class="bg-primary hr-new" data-aos="fade-right"><hr class="hr-2">
    <div class="container-fluid img-bloque">
      <div class="row">
        <div class="responsive-p col-md-3 mb-5 offset-md-2">
          <img class="mt-4 text-center" width="300"  src="../assets/Inicio/cart-01 1.png" alt="">
        </div>
        <div class="responsive-p text-center col-md-6 offset-md-1 mt-md-5">
          <h2 class=" text-uppercase text-primary mt-2"><b>Te ayudamos a vender online</b></h2>
          <p class="text-primary h4 mt-2">Programamos y monetizamos tu web, app o plataforma de forma rápida y segura.</p>
          <div class="row justify-content-center ">
            <div class="col-md-6 col-8 pl-5 offset-md-3 mt-2">
              <div class="row">
                <div class="col-4">
                  <img width="60" src="../assets/Inicio/appstore.png" alt="">
                </div>
                <div class="col-4">
                  <img width="60" src="../assets/Inicio/web.png" alt="">
                </div>
                <div class="col-4">
                  <img width="60" src="../assets/Inicio/google_play.png" alt="">
                </div>
              </div>
              <b-button pill variant="primary" size="lg" class="mt-4 shadow" @click="$router.push('/wizard')">
                <span class="h6 mr-2 font-weight-bold">COTIZAR</span>
                <b-icon icon="arrow-right" aria-hidden="true"></b-icon>
              </b-button>
            </div>
          </div>
        </div>
        <!-- responsive -->
        <div class="responsive-p2  col-md-3 mb-5 offset-md-2">
          <img class=" img-fluid" width="350" src="../assets/Inicio/cart-01 1.png" alt="">
        </div>
        <div class="responsive-p2 text-center col-md-4 offset-md-1 mt-md-5 ">
          <h2 class=" text-uppercase text-primary mt-2"><b>Te ayudamos a vender online</b></h2>
          <p class="text-primary h4 mt-2">Programamos y monetizamos tu web, app o plataforma de forma rapida y segura.</p>
          <div class="row">
            <div class="col-md-6  offset-md-3 mt-2">
              <div class="row">
                <div class="col-4">
                  <img width="60" src="../assets/Inicio/appstore.png" alt="">
                </div>
                <div class="col-4">
                  <img width="60" src="../assets/Inicio/web.png" alt="">
                </div>
                <div class="col-4">
                  <img width="60" src="../assets/Inicio/google_play.png" alt="">
                </div>
              </div>
              <b-button pill variant="primary" size="lg" class="mt-4 shadow" @click="$router.push('/wizard')">
                <span class="h6 mr-2 font-weight-bold">COTIZAR</span>
                <b-icon icon="arrow-right" aria-hidden="true"></b-icon>
              </b-button>
            </div>
          </div>
        </div>
        <!-- fin responsive -->
      </div> 
    </div>
    <!-- responsive-card -->
    <div class="responsive-card1 container-fluid margin-phone mb-5"> 
      <div class="row">
        <div class="col-md-12 text-center p-4">
          <h2 class=" text-uppercase text-primary mt-4"><b>Nuestros servicios</b></h2>
          <p class="text-primary h4 mt-2">Aseguramos tu crecimiento con nuestra tecnologia.</p>
        </div>
      </div>
      <div class="row justify-content-center align-items-center text-center" >
        <div class="col-md-3 ">
          <div class="card shadow shadow-hovershadow-hover pointer" @click="$router.push('/f-creweb')" data-aos="fade-down" data-aos-delay="100"  data-aos-easing="linear">
            <img class="card-img-top p-5" src="../assets/Inicio/1.png" alt="Card image cap">
            <div class="card-body">
              <p class="card-text text-primary h4 mb-4" >Creación de páginas web</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 pointer" @click="$router.push('/f-des-plataforma')" data-aos="fade-down"  data-aos-delay="300" data-aos-easing="linear">
          <div class="card shadow" >
            <img class="card-img-top p-5" src="../assets/Inicio/2.png" alt="Card image cap">
            <div class="card-body">
              <p class="card-text text-primary h4 mb-4">Desarrollo de Plataformas</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 ">
          <div class="card shadow shadow-hoverpointer" @click="$router.push('/f-chatbot-venta')" data-aos="fade-down"  data-aos-delay="500" data-aos-easing="linear">
            <img class="card-img-top p-5" src="../assets/Inicio/3.png" alt="Card image cap">
            <div class="card-body">
              <p class="card-text text-primary h4 ">Chatbot de ventas en whatsapp</p>
            </div>
          </div>
        </div>
      </div>
      <!-- segundo row cards -->
      <div class=" row mt-5 justify-content-center align-items-center text-center">
        <div class="col-md-3 ">
          <div class="card shadow shadow-hoverpointer" @click="$router.push('/f-pixel-f')" data-aos="fade-up" data-aos-delay="100">
            <img class="card-img-top p-5" src="../assets/Inicio/4.png" alt="Card image cap">
            <div class="card-body">
              <p class="card-text text-primary h4 ">Configuración de pixel de Facebook</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 ">
          <div class="card shadow shadow-hoverpointer" @click="$router.push('/f-camp-f')" data-aos="fade-up" data-aos-delay="300">
            <img class="card-img-top p-5" src="../assets/Inicio/5.png" alt="Card image cap">
            <div class="card-body">
              <p class="card-text text-primary h4 ">Campañas de ventas en Facebook y Google Adds</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 ">
          <div class="card shadow shadow-hoverpointer" @click="$router.push('/f-publi-redes')" data-aos="fade-up" data-aos-delay="500">
            <img class="card-img-top p-5" src="../assets/Inicio/6.png" alt="Card image cap">
            <div class="card-body">
              <p class="card-text text-primary h4 ">Publicación en Redes Sociales</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- fin responsive-card -->
    <!-- 2responsive-card -->
    <div class="responsive-card2 container-fluid margin-phone mb-5"> 
      <div class="row">
        <div class="col-md-12 text-center p-4">
          <h2 class=" text-uppercase text-primary mt-4"><b>Nuestros servicios</b></h2>
          <p class="text-primary h4 mt-2">Aseguramos tu crecimiento con nuestra tecnologia.</p>
        </div>
      </div>
      <div class="row justify-content-center align-items-center text-center">
        <div class="col-6 ">
          <div style="height:14rem;" class="card shadow shadow-hover pointer" @click="$router.push('/f-creweb')" data-aos="zoom-in" data-aos-delay="100">
             <div class="p-2">
              <img class="card-img-top mt-3" style="height:5rem; width:5rem; margin-bottom:1.7rem;" src="../assets/Inicio/1.png" alt="Card image cap">
            </div>
            <div  class="card-body mt-3">
              <p class="card-text text-primary h4 mb-4">Creación de páginas web</p>
            </div>
          </div>
        </div>
        <div class="col-6 ">
          <div style="height:14rem;" class="card shadow shadow-hoverpointer" @click="$router.push('/f-des-plataforma')" data-aos="zoom-in" data-aos-delay="200">
            <div class="p-2">
              <img class="card-img-top mt-3" style="height:5rem; width:5rem; margin-bottom:1.7rem;" src="../assets/Inicio/2.png" alt="Card image cap">
            </div>
            <div  class="card-body mt-3">
              <p class="card-text text-primary h4 mb-4">Desarrollo de Plataformas</p>
            </div>
          </div>
        </div>
        <div class="col-6 mt-3">
          <div style="height:14rem;" class="card shadow shadow-hoverpointer" @click="$router.push('/f-chatbot-venta')" data-aos="zoom-in" data-aos-delay="100">
            <div class="p-2">
              <img class="card-img-top mt-3" style="height:5rem; width:5rem; margin-bottom:1.7rem;" src="../assets/Inicio/3.png" alt="Card image cap">
            </div>
            <div  class="card-body mt-3">
              <p class="card-text text-primary h4 ">Chatbot de ventas en whatsapp</p>
            </div>
          </div>
        </div>
      <!-- </div> -->
      <!-- segundo row cards -->
      <!-- <div class="row mt-5 justify-content-center align-items-center text-center"> -->
        <div class="col-6 mt-3">
          <div style="height:14rem;" class="card shadow shadow-hoverpointer" @click="$router.push('/f-pixel-f')" data-aos="zoom-in" data-aos-delay="200">
            <div class="p-2">
              <img class="card-img-top mt-3" style="height:5rem; width:5rem; margin-bottom:1.7rem;" src="../assets/Inicio/4.png" alt="Card image cap">
            </div>
            <div class="card-body mt-3">
              <p class="card-text text-primary h4 ">Configuración de pixel de Facebook</p>
            </div>
          </div>
        </div>
        <div class="col-6 mt-3">
          <div style="height:14.5rem;" class="card shadow shadow-hoverpointer" @click="$router.push('/f-camp-f')" data-aos="zoom-in" data-aos-delay="100" >
            <div class="p-2">
              <img class="card-img-top mt-3" style="height:5rem; width:5rem; margin-bottom:1.7rem;" src="../assets/Inicio/5.png" alt="Card image cap">
            </div>
            <div  class="card-body mt-3">
              <p  class="card-text text-primary h4 ">Campañas de ventas en Facebook y Google Adds</p>
            </div>
          </div>
        </div>
        <div class="col-6 mt-3">
          <div style="height:14.5rem;" class="card shadow shadow-hoverpointer" @click="$router.push('/f-publi-redes')" data-aos="zoom-in" data-aos-delay="200">
            <div class="p-2">
              <img class="card-img-top mt-3" style="height:5rem; width:5rem; margin-bottom:1.7rem;" src="../assets/Inicio/6.png" alt="Card image cap">
            </div>
            <div  class="card-body mt-3">
              <p class="card-text text-primary h4 ">Publicación en Redes Sociales</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- fin 2responsive-card -->
    <div class="container-fluid bg-gray">
      <div class="row text-center">
        <div class="col-md-6 offset-md-1  p-5 ">
           <h3 class="text-capitalize text-primary mt-5" data-aos="fade-up"><b>Suscribete A Nuestro Boletin</b></h3>
           <p class=" mt-5 h5 padding-text" data-aos="fade-up" data-aos-delay="100">Suscribete a nuestro boletín para recibir las últimas noticias en ecommerce y descuentos a nuestros servicios</p>
            <div class="row justify-content-center align-items-center">
              <div class="col-9">
                <b-form @submit.prevent="sendData()">
                  <div class="input-group input-group-lg mb-3 mt-4 " data-aos="fade-up" data-aos-delay="200">
                    <input required v-model="email" type="email" class="form-control none-box-s" placeholder="Correo Electrónico" aria-label="Recipient's username" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                      <b-button squared  class="btn btn-primary" type="submit"><span class="h5 mr-2 ">Suscribirse</span><b-icon class="mb-1" font-scale="0.9" icon="envelope-fill" aria-hidden="true"></b-icon></b-button>          
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
        </div>
        <div class="col-md-3 " data-aos="fade-up" data-aos-delay="150">
           <img class="img-responsive2 img-fluid mt-3 mb-3" width="390" src="../assets/Inicio/suscription 1.png" alt="">
        </div>
      </div>
    </div>
    <div id="Contacto" class="container-fluid" >
      <div class="row">
        <div class="col-md-12">
          <h3 class=" text-uppercase text-primary mt-5 text-center mb-5"><b>CONTÁCTANOS</b></h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 offset-md-1 mr-md-2">
          <b-form @submit.prevent="sendContact()" class="form-group" >
            <input v-model="row.nombre" required type="text" class="form-control form-new none-box-s" id="" aria-describedby="emailHelp" placeholder="NOMBRE" data-aos="fade-right" >
            <input v-model="row.empresa" required type="text" class="form-control form-new none-box-s" id="" aria-describedby="emailHelp" placeholder="EMPRESA" data-aos="fade-right" data-aos-delay="100">
            <input v-model="row.email" required type="email" class="form-control form-new none-box-s" id="" aria-describedby="emailHelp" placeholder="EMAIL" data-aos="fade-right" data-aos-delay="200">
            
              <b-form-textarea style="font-size:16px"
                required v-model="row.mensaje"
                rows="4" cols="50"
                class="form-new mr-2 ml-2 none-box-s"
                id="textarea"
                size="lg"
                placeholder="MENSAJE"
                data-aos="fade-right" data-aos-delay="300"
              ></b-form-textarea>
            
          
            <div class="text-md-right text-center mb-5">
              <b-button type="submit" pill variant="primary" size="lg" class="shadow">
                  <span class="h6 mr-2 font-weight-bold">ENVIAR</span>
                  <b-icon icon="arrow-right" aria-hidden="true"></b-icon>
                </b-button>
            </div>
          </b-form>
        </div>
        <div class="col-md-4 ml-4 ml-md-5 ">
          <div class="row mt-3">
            <div class="col-md-1">
              <b-icon icon="building" font-scale="1.5" variant="primary" ></b-icon>
            </div>
            <div class="col-md-11">
              <p class="h5 text-capitalize text-secondary">Samara towers torres santa fe, ciudad de mexico</p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-1">
              <b-icon icon="telephone-fill" font-scale="1.5" variant="primary" ></b-icon>
            </div>
            <div class="col-md-11">
              <p class="h5 text-capitalize text-secondary" ><a  href="tel:733-125-83-24" class="text-secondary"> 733-125-83-24</a></p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-1">
              <b-icon icon="envelope-fill" font-scale="1.5" variant="primary" ></b-icon>
            </div>
            <div class="col-md-11">
              <p class="h5 text-secondary"><a href="mailto:ventas@techsoftconsulting.mx" class="text-secondary"> ventas@techsoftconsulting.mx</a></p>
            </div>
          </div>
          <div class="text-center mt-5"><p class="text-secondary text-uppercase display-4">SÍGUENOS</p></div>
          <div class="row justify-content-center text-center mb-5">
            <div class="col-3 margin-icons">
              <a href="https://www.facebook.com/techSoft01/"><img style="max-height: 50px; max-width: 50px;" class="margin-icons" src="../assets/Inicio/facebook.png" alt=""></a>
            </div>
            <div class="col-3 margin-icons">
              <a href="https://www.instagram.com/techsoftconsulting/?hl=es"><img style="max-height: 50px; max-width: 50px;" class="margin-icons" src="../assets/Inicio/instagram.png" alt=""></a>
            </div>
            <div class="col-3 margin-icons">
              <a href="https://www.linkedin.com/company/techsoftconsulting"><img style="max-height: 50px; max-width: 50px; " class="margin-icons" src="../assets/Inicio/linkeding.png" alt=""></a>
            </div>
            <!-- <div class="col-md-12 ">
              <h4 class="text-uppercase-2 text-dark mt-3 text-center mr-3 ml-md-1"><b>SÍGUENOS</b></h4>
              <div class="row mb-5 mt-4 justify-content-center align-items-center" >
                <div class="col-2 ">
                  <a href="https://www.facebook.com/techSoft01/"><img class="img-fluid img-responsive" src="../assets/Inicio/facebook.png" alt=""></a>
                </div>
                <div class="col-2">
                  <a href="https://www.instagram.com"><img class="img-fluid img-responsive" src="../assets/Inicio/instagram.png" alt=""></a>
                </div>
                <div class="col-2">
                  <a href="https://www.linkedin.com/company/techsoftconsulting"><img class="img-fluid img-responsive" src="../assets/Inicio/linkeding.png" alt=""></a>
                </div>
              </div>
            </div> -->
          </div>
          
          <!-- <p class="h4 text-capitalize text-secondary ml-3"><b-icon icon="exclamation-circle-fill" variant="primary mr-3" ></b-icon>Samara towers torres santa fe, ciudad de mexico</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      email:'',
      row: {}
    }
  },
  methods:{
    async sendContact(){
      let data ={
        name : this.row.nombre,
        company: this.row.empresa,
        email: this.row.email,
        message: this.row.mensaje
      }
      let options = {
        method: "post",
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(data)
      }
      let req = await fetch(this.$store.state.url + 'contact', options)
      alert('Datos enviados correctamente.')
      this.row= {}
    },
    async sendData(){
      let options = {
        method: "post",
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({email : this.email})
      }
      let req = await fetch(this.$store.state.url + 'boletinUsers', options)
      alert('Correo registrado correctamente.')
    }
  }
};
</script>

<style scoped>
@import '../assets/css/styles-new.css';
.none-box-s{
  box-shadow: none !important;
}
.img-bakground {
  /* The image used */
  background-image: url('../assets/Inicio/imgfond.png');

  /* Full height */
  max-height: 670px;
  
  max-width: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.img-bloque {
  /* The image used */
  background-image: url('../assets/Inicio/bloque.png');

  /* Full height */
  height: 33.5rem;
  /* margin-top: -3%;
  max-width: flex; */ 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#new-home{
   font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align:initial !important;
  
}
@media only screen and (max-width: 780px) {
  .img-bloque {
  /* The image used */
  background-image: none;

  /* Full height */
  height: 33.5rem;
  /* margin-top: -3%;
  max-width: flex; */ 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

}
</style>
