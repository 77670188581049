<template>
  <div id="navbar" >
    <!-- desktop -->
    <div class="responsive-p2 ml-md-5 mr-md-5 ">
      <b-navbar toggleable="lg" type="dark" class="m-auto">
        <b-navbar-brand  style="cursor: pointer" @click="$router.push('/').catch((err) => {})"
          ><img class="ml-md-5" src="../assets/logo_T.png" width="190" alt=""
        /></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse" ></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <!-- <b-link></<router-link>b-link> -->
            <b-nav-item class="mt-4" active @click="$router.push('/').catch((err) => {})"
              ><span class=" text-color text-responsive h5 mr-md-5 text-white"> Inicio </span></b-nav-item
            >
            <b-nav-item class="mt-4  " @click="$router.push('/f-nosotros').catch((err) => {})"
              ><span class="text-color text-responsive h5 mr-md-5 text-white"> Nosotros </span></b-nav-item
            >
            <b-nav-item class="mt-4 " @click="$router.push('/f-25kclub').catch((err) => {})"
              ><span class="text-color  h5 mr-md-5 text-white"> The 25k Club </span></b-nav-item
            >
            <b-nav-item class="mt-4  " @click="$router.push('/f-vendemas').catch((err) => {})"
              ><span class="text-color text-responsive h5 mr-md-5 text-white">Portafolio</span></b-nav-item
            >
            <!-- <b-nav-item class="mt-4 " @click="$router.push('/f-vendemas').catch((err) => {})"
              ><span class="text-color text-responsive h5 mr-md-5 text-white">Servicios</span></b-nav-item
            > -->
            <b-nav-item-dropdown  text="Servicios" right class="mt-4 active" style="color: white !important; font-size:1.2rem" >
              <b-dropdown-item @click="$router.push('/f-creweb').catch((err) => {})"
                >Creación de página web</b-dropdown-item
              >
              <b-dropdown-item @click="$router.push('/f-des-plataforma')">Desarrollo de plataformas</b-dropdown-item>
              <b-dropdown-item @click="$router.push('/f-chatbot-venta')">Chatbot de ventas en whatsapp</b-dropdown-item>
              <b-dropdown-item @click="$router.push('/f-pixel-f')">Configuración Pixel de facebook</b-dropdown-item>
              <b-dropdown-item @click="$router.push('/f-camp-f')">Campañas de venta en Facebook </b-dropdown-item>
              <b-dropdown-item @click="$router.push('/f-publi-redes')">Publicación en redes sociales</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item class="nav-link mt-3 " @click="$router.push('/#Contacto').catch((err) => {})"
              ><span class=" text-color text-responsive mb-5 text-white  h5 mr-md-5">Contacto </span></b-nav-item
            >
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    
    <!-- pantalla pequeña -->
    <div class="responsive-p">
      <b-navbar toggleable="lg" type="dark" class="m-auto" >
        <b-navbar-brand style="cursor: pointer" @click="$router.push('/').catch((err) => {})"
          ><img class="ml-md-5" src="../assets/logo_T.png" width="170" alt=""
        /></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse  id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto" >
            <!-- <b-link></<router-link>b-link> -->
            <b-nav-item class="mt-4" active @click="$router.push('/').catch((err) => {})"
              ><span class="text-color  text-responsive h5 mr-md-3 text-white"> Inicio </span></b-nav-item
            >
            <b-nav-item class="mt-4  " @click="$router.push('/f-nosotros').catch((err) => {})"
              ><span class="text-color text-responsive h5 mr-md-3 text-white"> Nosotros </span></b-nav-item
            >
            <b-nav-item class="mt-4 " @click="$router.push('/f-25kclub').catch((err) => {})"
              ><span class="text-color  h5 mr-md-3 text-white"> The 25k Club </span></b-nav-item
            >
            <b-nav-item class="mt-4  " @click="$router.push('/f-vendemas').catch((err) => {})"
              ><span class="text-color text-responsive h5 mr-md-3 text-white">Portafolio</span></b-nav-item
            >
            
            <b-nav-item-dropdown  text="Servicios "  class="mt-4 active" style="color: white !important; font-size:1.2rem" >
              <b-dropdown-item @click="$router.push('/f-creweb').catch((err) => {})"
                >Creación de página web</b-dropdown-item
              >
              <b-dropdown-item @click="$router.push('/f-des-plataforma')">Desarrollo de plataformas</b-dropdown-item>
              <b-dropdown-item @click="$router.push('/f-chatbot-venta')">Chatbot de ventas en whatsapp</b-dropdown-item>
              <b-dropdown-item @click="$router.push('/f-pixel-f')">Configuración Pixel de facebook</b-dropdown-item>
              <b-dropdown-item @click="$router.push('/f-camp-f')">Campañas de venta en Facebook </b-dropdown-item>
              <b-dropdown-item @click="$router.push('/f-publi-redes')">Publicación en redes sociales</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item class="nav-link mt-3 " @click="$router.push('/#Contacto').catch((err) => {})"
              ><span class=" text-color text-responsive mb-3 text-white  h5 mr-md-5">Contacto </span></b-nav-item
            >
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <!-- end 2block -->
  </div>
  
</template>

<script>
export default {
  props: ["position"],
  data() {
    return {
    };
  },
  mounted() {},
  methods: {
    goto(item) {
      item;
    },
  },
};
</script>

<style scoped>
.bg-navbar {
  background-color: #12045e !important;
}
.bg-yellow {
  background-color: yellow;
  color: black;
}
.dropdown-menu {
  background-color: transparent;
}
.logo {
  width: 70%;
  height: 80%;
}
/* .text-color:hover{
  color: #07cf83;
}
.text-color{
  color: #e9e6e6;
} */

@media only screen and (max-width: 780px) {
  .logo {
    width: 50%;
    height: 60%;
  }
}
</style>