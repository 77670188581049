import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home-new.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home-new',
    component: () => import('../views/Home-new.vue')

    //component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')

    //component: Home
  },
  {
    path: '/formwizard',
    name: 'formwizard',
    component: () => import('../views/formulario.vue')
    // component: () => import('../components/cres_ventas-2.vue')

    //component: Home
  },
  {
    path: '/f-nosotros',
    name: 'f-nosotros',
    component: () => import('../views/f-nosotros.vue')
    // component: () => import('../components/cres_ventas-2.vue')

    //component: Home
  },
  {
    path: '/f-25kclub',
    name: 'f-25kclub',
    component: () => import('../views/f-25kclub')
    // component: () => import('../components/cres_ventas-2.vue')

    //component: Home
  },
  {
    path: '/f-vendemas',
    name: 'f-vendemas',
    component: () => import('../views/f-vendemas')
    // component: () => import('../components/cres_ventas-2.vue')

    //component: Home
  },
  {
    path: '/f-creweb',
    name: 'f-creweb',
    component: () => import('../views/f-creweb')
    // component: () => import('../components/cres_ventas-2.vue')

    //component: Home
  },
  {
    path: '/f-pixel-f',
    name: 'f-pixel-f',
    component: () => import('../views/f-pixel-f')
    // component: () => import('../components/cres_ventas-2.vue')

    //component: Home
  },
  {
    path: '/f-camp-f',
    name: 'f-camp-f',
    component: () => import('../views/f-camp-f')
    // component: () => import('../components/cres_ventas-2.vue')

    //component: Home
  },
  {
    path: '/f-des-plataforma',
    name: 'f-des-plataforma',
    component: () => import('../views/f-des-plataforma')
    // component: () => import('../components/cres_ventas-2.vue')

    //component: Home
  },
  {
    path: '/f-publi-redes',
    name: 'f-publi-redes',
    component: () => import('../views/f-publi-redes')
    // component: () => import('../components/cres_ventas-2.vue')

    //component: Home
  },
  {
    path: '/f-chatbot-venta',
    name: 'f-chatbot-venta',
    component: () => import('../views/f-chatbot-venta')
    // component: () => import('../components/cres_ventas-2.vue')

    //component: Home
  },

  {
    path: '/pdf',
    name: 'pdf',
    component: () => import('../views/pdf.vue')
  },
  {
    path: '/wizard',
    name: 'wizard',
    component: () => import('../views/wizard.vue')
  },
  {
    path: '/wizard_w',
    name: 'wizard_w',
    component: () => import('../views/wizard_w.vue')

    //component: Home
  },
  {
    path: '/wizard_commerce',
    name: 'wizard_commerce',
    component: () => import('../views/wizard_commerce.vue')

    //component: Home
  },
  {
    path: '/Chatbot_w',
    name: 'Chatbot_w',
    component: () => import('../views/Chatbot_w.vue')

    //component: Home
  },
  {
    path: '/Chatbot_w2',
    name: 'Chatbot_w2',
    component: () => import('../views/Chatbot_w2.vue')

    //component: Home
  },
  {
    path: '/cres_ventas',
    name: 'cres_ventas',
    component: () => import('../views/cres_ventas.vue')

    //component: Home
  },
  {
    path: '/users',
    name: 'users',
      component: () => import('../components/users.vue')
  },
  // {
  //   path: '/products',
  //   name: 'Products',
  //     component: () => import('../views/Products.vue')
  // },
  {
    path: '/public',
    name: 'public',
    component: () => import(/* webpackChunkName: "about" */ '../views/Public.vue')
  },
  {
    path: '/login_tech',
    name: 'login_tech',
    component: () => import(/* webpackChunkName: "about" */ '../views/login_tech.vue')
  },
  {
    path: '/portafolio',
    name: 'portafolio',
    component: () => import('../views/portafolio.vue')
  },
  {
    path: '/Terms',
    name: 'Terms',
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/politics',
    name: 'politics',
    component: () => import('../views/politics.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: function(to, from, savedPosition) {
      if (to.hash) {
          return {selector: to.hash}
      } else {
          return { x: 0, y: 0 }
      }
  },  
  routes
})

export default router
