<template>
  <div id="app">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
      <Navbar style="position:absolute;" />
    <router-view />
    <div class="navbar-fixed-bottom footer  text-right pr-3 "  >
    <a href="https://wa.me/525538416547" target="_blank" class="btn btn-success btn-circle btn-xl  " id="shadow-w">
      <i class="fa fa-whatsapp h1"></i> 
    </a>
       <!-- <button href="" type="button" class="btn btn-success btn-circle btn-xl  " id="example1"><i class="fa fa-whatsapp h1"></i></button> -->
      
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from './components/Footer.vue'
import Navbar from './components/Navbar.vue'


export default {
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      scrollPosition: null
    };
  },
  mounted() {
    // window.addEventListener('scroll', this.updateScroll)
  },
  methods:{
    updateScroll(){
      // console.log('scroll ' , window.scrollY)
      // this.scrollPosition = window.scrollY
    }
  }
};
</script>
<style>
/* @import "./assets/css/styles.css"; */

#shadow-w {
  border: 1px solid;
  padding: 10px;
  box-shadow: 1px 10px 8px #2f2f2f60;
}
.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}



/* @import "./assets/css/styles.css"; */
.btn-success{
  background-color:#25D366 !important;
  border-color: #25D366;
}
.btn-success:hover{
  background-color:#1db956 !important;
  border-color: #1db956;
}
.btn-success:focus{
  background-color:#0cb148 !important;
  border-color: #0cb148;
}
.footer {
    position: fixed;
    height: 90px;
    bottom: 0;
    width: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#detail {
  padding: 0px;
  width: 100% !important;
}

#nav a {
  font-weight: bold;
  
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.pointer{
  cursor:pointer
}
.text-titulo-header{
  color: rgb(255, 255, 255);
  font-size: 80px;

}
.text-titulo-header-nosotros{
  color: rgb(255, 255, 255);
  font-size: 80px;

}

.text-titulo-header-25k{
  color: rgb(255, 255, 255);
  font-size: 80px;
  text-align: left

}
.text-titulo-header-chatbot{
  color: rgb(255, 255, 255);
  font-size: 70px;
  text-align: left

}
.text-titulo-header-pixel{
  color: rgb(255, 255, 255);
  font-size: 70px;
  text-align: left

}
.text-titulo-header-creweb{
  color: rgb(255, 255, 255);
  font-size: 80px;
  text-align: left

}
.text-titulo-header-des-p{
  color: rgb(255, 255, 255);
  font-size: 80px;
  text-align: left

}
.text-titulo-header-vendemas{
  color: rgb(255, 255, 255);
  font-size: 80px;
  text-align: left

}

.text-header{
  color: rgb(255, 255, 255);
  font-size: 25px;
}
.text-header-nosotros{
  color: rgb(255, 255, 255);
  font-size: 25px;
  text-align: left;
  margin-top: 5%;
}
.text-header-25k{
  color: rgb(255, 255, 255);
  font-size: 25px;
  text-align: left;
  margin-top: 5%;
}
.text-header-chatbot{
  color: rgb(255, 255, 255);
  font-size: 25px;
  text-align: left;
  margin-top: 5%;
}
.text-header-creweb{
  color: rgb(255, 255, 255);
  font-size: 25px;
  text-align: left;
}
.text-header-des-p{
  color: rgb(255, 255, 255);
  font-size: 25px;
  text-align: left;
  margin-top: 5%;
}
.text-header-vendemas{
  color: rgb(255, 255, 255);
  font-size: 25px;
  text-align: left;
  margin-top: 5%;
}
.text-row-home1{
  font-size: 35px;
  color: rgb(255, 255, 255);
  margin-top: -2%;
}
.text-titulo-home2{
  font-size: 45px;
}
.img-home2{
  max-width: 100%;
  max-height: 100%;
  margin-top: 35%;
}
.padding-img-home2{
  padding-left: 20%;
  padding-right: 20%;
}
.top-home2{
  position: static;
  display: inline-block;
  /* top: 30%; */
}
.tamaño-imgBig-home2{
  max-width: 85%;
  max-height: 85%;
}
.text-normal{
  font-size: 22px;
}
.button-vendemas{
  
}
.margin-text-titulo{
  padding-top: 15%;
}
.margin-text-titulo-nosotros{
  padding-top: 9%;
}
.margin-text-titulo-25k{
  padding-top: 5%;
}
.margin-text-titulo-chatbot{
  padding-top: 5%;
}
.margin-text-titulo-creweb{
  padding-top: 5%;
}
.margin-text-titulo-des-p{
  padding-top: 5%;
}
.margin-text-titulo-vendemas{
  padding-top: 5%;
}
.button-heaader-home1{
  width: 30%;
  height: 100%;
  margin-bottom: 15%;
  
}
.button-heaader-nosotros{
  width: 30%;
  height: 100%;
  
}
.button-home2{
  height: 100%;
   width: 100%;
 
  
}
.text-button-home1{
  font-size:22px;
  margin-top: 5%;
  
}
.text-button-home2{
  font-size:22px;
}
.fondo-row-home1{
  background-color: #168eff;
  /* background-color: #277024; */
  max-width:auto;
  height: 160px;
}
.fondo-row-nosotros{
  background-color: #168eff;
  min-width:157.4% ;
  height: 27%;
}
.imagen-row-home1{
  max-height: 40%;
  max-width: 40%;
  margin-top: 5%;
}

.text-titulo-home4{
  font-size: 45px;
  color: #12045e;
}
.text-subtitulo-home4{
  font-size: 25px;
}
.text-titulo-home3{
  font-size: 45px;
  color: #12045e;
}
.text-subtitulo-home3{
  font-size: 25px;
}



.main {
display: grid;
grid-template-columns: auto auto;
grid-column-gap: 40px;
margin-inline-end: -0.9%;
margin-inline-start: -0.9%;
                
}
.main2 {
display: grid;
grid-template-columns: auto auto;
grid-column-gap: 40px;
margin-inline-end: -0.9%;
margin-inline-start: -0.9%;
                
}
.main2 > div {
text-align: center;
padding: 0px;
font-size: 50px;
color: rgb(255, 255, 255);
padding: 15%;
} 
 
.main > div {
text-align: center;
padding: 0px;
font-size: 50px;
color: rgb(255, 255, 255);
padding: 17%;
}
/* ---------------------------------- */
.main-25k {
display: grid;
grid-template-columns: auto auto;
grid-column-gap: 40px;
margin-inline-end: -0.9%;
margin-inline-start: -0.9%;
                
}
.main2-25k {
display: grid;
grid-template-columns: auto auto;
grid-column-gap: 40px;
margin-inline-end: -0.9%;
margin-inline-start: -0.9%;
                
}
.main2-25k > div {
text-align: center;
padding: 0px;
font-size: 50px;
color: rgb(255, 255, 255);
padding: 15%;
} 
 
.main-25k > div {
text-align: center;
padding: 0px;
font-size: 50px;
color: rgb(255, 255, 255);
padding: 17%;
}
/* ---------------------------------- */
.button-h4{
  /* width: 20%;
  height: 100%;
  padding: 10%;
  margin-bottom: 3%; */
}

.text-button-h4{
  font-size:22px;
  margin-top: 5%;
  
}
.img-direccion-h4{
  max-width: 180%;
  max-height: 180%;


}
.text-h4{
  font-size: 22px;
  margin-top: -3%;
}
.text2-h4{
  font-size: 22px;;
}
.text3-h4{
  font-size: 22px;;
}
.img-telefono-h4{
  width: 180%;
  height: 180%;
}
.img-gmail-h4{
  width: 160%;
  height: 180%;
}
.img-icons-h4{
  width: 35px;
  
}
.img-icons-h42{
  width: 65px;
  margin-left: 5%;
  
}
.img-icons-h42:hover{
 filter: brightness(0.90);
}
.button-h4-enviar{
  min-width: 30%;
  margin-right: 25%;
  
}
.tamaño-div-nosotros{
  width: 70%;
  margin-left:20% ;
}
.tamaño-div-25k{
  width: 100%;
}
.tamaño-div-chatbot{
  width: 100%;
  margin-top: -5%;
}
.tamaño-div-creweb{
  width: 100%;
}
.tamaño-div-des-p{
  width: 100%;
}
.tamaño-div-vendemas{
  width: 100%;
}
.img-header-nosotros{
  width: 70%;
  margin-top: -12%;
  display: inline;
}
.img-header-25k{
  width: 70%;
  margin-top: -5%;
}
.img-header-creweb{
  width: 70%;
  margin-top: -5%;
}
.img-header-des-p{
  width: 80%;
  margin-top: -5%;
  margin-left: -5%;
}
.img-header-vendemas{
  width: 70%;
  margin-top: -5%;
}
.text-titulo-nosotros{
  font-size: 45px;
  color: #12045e;
  margin-top: 10%;
  text-align: left;
}
.text-titulo-vendemas{
  font-size: 45px;
  color: #12045e;
  margin-top: 10%;
}
.text-titulo-vendemas{
  font-size: 45px;
  color: #12045e;
  margin-top: 5%;
  text-align: left;
}
.div-vendemas{
  margin-left: 5%;
}
.text-titulo-25k{
  font-size: 45px;
  color: #12045e;
  margin-top: 10%;
}
.text-titulo-chatbot{
  font-size: 45px;
  color: #12045e;
  margin-top: 10%;
  text-align: left;
}
.text-titulo-creweb{
  font-size: 45px;
  color: #12045e;
  margin-top: 10%;
}
.text-titulo-25k2{
  font-size: 45px;
  color: #12045e;
}
.p-nosotros{
  font-size: 25px;
  width: 90%;
}
.p-vendemas{
  font-size: 25px;
  width: 90%;
  text-align: justify;
}
.p-vendemas2{
  font-size: 25px;
  width: 90%;
  text-align: justify;
  margin-top: -3.5%;
}
.p-25k{
  font-size: 25px;
  width:90%;
}
.p-chatbot{
  font-size: 25px;
  width:90%;
}
.p-chatbot2{
  font-size: 25px;
  width:90%;
}
.p-creweb{
  font-size: 25px;
  width:90%;
}
.text-align-l-nosotros{
  text-align: justify;
}
.text-align-l-vendemas{
  text-align: justify;
}
.text-align-l-25k{
  text-align: justify;
}
.text-align-l-chatbot{
  text-align: justify;
}
.img-nosotros-grafica{
  width: 55%;
  height: 55%;
  margin-top: 12%;
  display: inline;
}
.img-inmobina{
  width: 55%;
  height: 55%;
  margin-top: 12%;
  display: inline;
}
.img-nosotros-embudo{
  width: 100%;
  height: 100%;
  margin-top: 10%;
}
.img-whatsapp{
  max-width: 100%;
  max-height: 100%;
  margin-top: 10%;
}
.img-creweb{
  width: 70%;
  height: 70%;
  margin-top: 5%;
}
.button-nosotros{
  width: 40%;
  height: 10%;
}
.button-h4{
  width: 20%;
  height: 10%;
}
.button-vendemas{
  width: 40%;
  height: 10%;
}
.text-button-nosotros{
   font-size:22px;
  margin-top: 5%;
}
.text-button-h4{
   font-size:22px;
  margin-top: 5%;
}
.text-button-vendemas{
   font-size:22px;
  margin-top: 5%;
}
.img-nosotros-garantia{
  margin-top: 20%;
  max-width: 90%;
  max-height: 90%;
}
.text-titulo-nosotros-2{
  font-size: 45px;
  color: #12045e;
  margin-top: 10%;
  text-align: right;
}
.p-nosotros-2{
  font-size: 25px;
  text-align: right;
}
.text-p-min-nosotros{
  text-align: right;
  font-size: 16px;
}
.button-nosotros2{
  width: 40%;
  height: 10%;
 
}
.button-creweb{
  width: 20%;
  height: 13%;
 
}
.left-vendemas{
  text-align: left;
}
.text-ehuyz {
  font-size:22px;
  
}
.button-nosotros2{
  width: 40%;
  height: 10%;
  
}
.text-button-nosotros2{
  font-size:22px;
}
.line-text-25k{
  text-decoration-line: underline;
  text-decoration-style: solid;
}
.text-25k{
  color: #fffcf8;
  text-align: left;
  font-size: 18px;
}
.text-chatbot{
  color: #fffcf8;
  text-align: left;
  font-size: 18px;
}
.text-chatbot2{
  text-align: center;
  font-size: 25px;
}
.text-creweb{
  color: #fffcf8;
  text-align: left;
  font-size: 18px;
}
.text-des-p{
  color: #fffcf8;
  text-align: left;
  font-size: 18px;
}
.text-vendemas{
  color: #fffcf8;
  text-align: left;
  font-size: 18px;
}


.padre {
   display: flex;
   align-items: center;
}
.z-cuadros-25k{
  height:400px !important;
  margin-top: 5%;
}
.text-cuadros-25k{
  color:white;
  display:block; 
  margin: 0 auto; 
  font-size:3rem !important;
  text-align: left;
}
.z-cuadros{
  height:400px !important;
  margin-top: 5%;
}
.text-cuadros{
  color:white;
  display:block; 
  margin: 0 auto; 
  font-size:3rem;
}
.top1{
  position: absolute;
  top: -5%;
}
.columna_input{
    margin-left: 10%;
}
.tamaño_input{
    width:100%
}
.sombra{
      box-shadow: 0 1px 1px 0 rgba(109, 115, 124, 0.198), 0 0px 7px 0 rgba(45, 112, 255, 0.19);
}
.columna_derecha{
    margin-top: 5%;
}
.text-25k-cuadros{
  color:white;
  font-size:1.6rem; 
  text-align:justify
}
.text-titulo-25k-div-final{
  font-size: 45px;
  color: #12045e;
  margin-top: 8%;
}
.img-cohete-25k{
  width: 15%;
  height: 15%;
  
}
.margin-25k-cohete{
  margin-top: -5%;
}
.text-titulo-25k-cohete{
  font-size: 45px;
  color: #12045e;
  margin-top: 7%;
}
.margin-div2-25k{
  margin-top: -5%;
}
.text-p-cohete-25k{
  font-size: 25px;
}
.div-text-choete-25k{
  text-align: left;
}
.div2-text-choete-25k{
  text-align: right;
}
.margin-b-45k{
  margin-bottom: 100%;
}
.color-bakground-creweb{
  background-color: #168eff;
  
}
.img-tasa-bateo{
  width: 80%;
  height: 100%;
  padding: 5%;
}
.text-white-creweb{
  color:rgb(255, 255, 255);
  font-size: 45px;
  margin-top: 15%;
}
.text-tasa-bateo{
  text-align: right;
  color: rgb(251, 251, 251);
  font-size: 25px;
}
.text-titulo-tasa-bateo{
  font-size: 45px;
  color: #12045e;
  margin-top: 5%;
}
.text-p-tasa-bateo{
  font-size: 25px;
}
.big{
  height:600px;
}
.div-button-home2{
  margin-left:23%
}
.text-left-ul{
  text-align: left;
  margin-left: 5%;
}
.align-button-nosotros{
  text-align: right;
}
.linea{
  border: 2px solid #174cfb;
  border-radius: 5px;
  width: 65px;
}

.row{
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.colr-back-row-h1{
  background-color: #168eff;
  border-color: #4069ee;
}
.colr-back-row-h1:hover{
  background-color: #208ff8;
  border-color: #174cfb;
}
.img-row-blue{
  max-width: 5.5rem;
  max-height: 5.5rem;
}
.mar-top-row-blue{
  margin-top: 1.5%;
  margin-bottom: 0.1rem;
}
.text-row-blue{
  color: rgb(255, 255, 255);
  font-size: 2rem;
  
}
.margin-text-blue{
  margin-bottom: 3%;
}
.div-clas{
  margin-top: -1rem !important;
}

</style>
