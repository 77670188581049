<template>
   <div class="bg-dark container-fluid p-3">
    <div class="text-center">
      <span style="font-size:20px;" class=" text-light">COPYRIGHT </span>
      <p @click.prevent="goto(1)" class="h5 fontfamily cursor text-light">Términos y condiciones</p>
      <p @click.prevent="goto(2)" class="h5 fontfamily cursor text-light">Políticas de privacidad</p>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{

    }
  },
  mounted(){

  },
  methods:{
    goto(id){
      if(id== 1)
        this.$router.push('/terms')
      if(id== 2)
        this.$router.push('/politics')
    }
  }
}
</script>

<style>
.cursor{
  cursor: pointer;
  }
</style>
